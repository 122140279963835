import React from 'react';
import {
    Box, Typography, Grid,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../layout/Layout';
import colors from '../theme/colors';

const introWrapper = {
    minHeight: '384px',
    padding: '193px 136px 108px',
    maxWidth: '1440px',
    width: '100%',
};
const objectivesWrapper = {
    padding: '65px 136px 74px',
    minHeight: '271px',
    maxWidth: '1440px',
    width: '100%',
};
const infoWrapper = {
    minHeight: '443px',
    padding: '81px 136px 92px',
    maxWidth: '1440px',
    width: '100%',
};
const enviromentWrapper = {
    minHeight: '246px',
    padding: '40px 136px 65px',
    maxWidth: '1440px',
    width: '100%',
};

const spaceJustifiedContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-evenly',
};
const flexContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
};

const AboutUsImage = () => (
    <StaticImage
        src="../images/about-us-main-image.svg"
        alt="Nosotros"
        layout="fixed"
        style={{
            position: 'absolute',
            width: '408px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
        width={408}
        height={545}
    />
);
const AboutUs = () => (
    <Layout>
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '144px 24px 63px', minHeight: 'auto' }, ...introWrapper })}>
                <Grid container style={{ minHeight: '198px' }}>
                    <Grid item xs={12} lg={7} sx={{ ...spaceJustifiedContainer }}>
                        <Typography variant="sectionH2" mb={3}>
                            Nosotros
                        </Typography>
                        <Typography variant="h4" style={{ fontWeight: 400, lineHeight: '33.6px' }}>
                            Somos una empresa dedicada a dar servicios de nube pública y privada, con
                            {' '}
                            <strong>foco en brindar una plataforma</strong>
                            {' '}
                            para desarrollar, probar, desplegar, utilizar y mantener aplicaciones, con un
                            {' '}
                            <strong>esquema de precios transparente y predecible.</strong>
                        </Typography>
                    </Grid>
                    <Grid item md={0} lg={1} />
                    <Grid item lg sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}>
                        <Box
                            sx={{
                                WebkitFilter: 'grayscale(100%)',
                                filter: 'grayscale(100%)',
                            }}
                        >
                            <AboutUsImage />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.burgundy,
                boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '60px 24px', minHeight: 'auto' }, ...objectivesWrapper })}>
                <Grid container style={{ minHeight: '174px' }}>
                    <Grid item xs={12} lg={2} display="flex" alignItems="center">
                        <Box>
                            <StaticImage src="../images/accuracy_icon.svg" alt="Objetivo" />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={5}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { paddingTop: '24px' }, ...spaceJustifiedContainer })}
                    >
                        <Typography variant="h4" style={{ fontfamily: 'Montserrat', lineHeight: '25.2px' }} mb={2} mt={1}>
                            Nuestro objetivo
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '25.2px' }} mb={2}>
                            Tenemos como objetivo
                            {' '}
                            <strong>ayudarte a diseñar la mejor arquitectura</strong>
                            {' '}
                            para tus aplicaciones.
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '25.2px' }}>
                            Considerando los recursos necesarios, la redundancia que necesites, tu proyección de crecimiento, tu stack tecnológico y tu esquema de despliegues.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '60px 24px', minHeight: 'auto' }, ...infoWrapper })}>
                <Grid container style={{ minHeight: '383px' }}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        pr={4}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '0px', paddingBottom: '36px' }, paddingRight: '46px', ...flexContainer })}
                    >
                        <Box style={{ height: '94px' }}>
                            <StaticImage src="../images/engine_icon.svg" alt="Engranajes" />
                        </Box>
                        <Typography variant="h4" style={{ fontfamily: 'Montserrat' }} mb={2} mt={2}>
                            Cómo trabajamos
                        </Typography>
                        <Typography variant="h5" mb={2} style={{ lineHeight: '25.2px' }}>
                            <strong>Planificamos en conjunto</strong>
                            {' '}
                            la migración y adopción de la nube en tu organización, teniendo en cuenta todos los detalles para no impactar en la operación de tu negocio.
                        </Typography>
                        <Typography variant="h5" mb={1} style={{ lineHeight: '25.2px' }}>
                            Los miembros de nuestros equipos van más allá de las necesidades actuales de nuestros clientes para acelerar lo que está por venir. Brindando un portfolio de productos y servicios en búsqueda de una mejora continua. Nos identificamos brindado un
                            {' '}
                            <strong>soporte ágil y personalizado</strong>
                            {' '}
                            diferenciándonos de los demás servicios de nube pública.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '0px' }, paddingLeft: '46px', ...flexContainer })}
                    >
                        <Box sx={{ height: '94px' }}>
                            <StaticImage src="../images/about-us-star_icon.svg" alt="Estrella" />
                        </Box>
                        <Typography variant="h4" style={{ fontfamily: 'Montserrat' }} mb={2} mt={2} pr={4}>
                            De dónde venimos
                        </Typography>
                        <Typography variant="h5" mb={2} style={{ lineHeight: '25.2px' }}>
                            Surgimos desde la
                            {' '}
                            <strong>experiencia de un Partner de HPE</strong>
                            , el cual se encuentra brindando soluciones de tecnología desde hace 30 años. Este recorrido nos permitió crear una plataforma de
                            {' '}
                            <strong>nube escalable, redundante y performante</strong>
                            , lista para correr cualquier tipo de servicio.
                        </Typography>
                        <Typography variant="h5" mb={1} style={{ lineHeight: '25.2px' }}>
                            Nuestra infraestructura es integramente HPE de última generación, OpenStack para la virtualización y Ceph para nuestro sistema de almacenamiento.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.burgundy,
                boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '60px 24px', minHeight: 'auto' }, ...enviromentWrapper })}>
                <Grid container style={{ minHeight: '147px' }}>
                    <Grid item xs={12} md={3} sx={{ ...spaceJustifiedContainer }}>
                        <Box>
                            <StaticImage src="../images/footprint_icon.svg" alt="Huella" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ ...spaceJustifiedContainer }} style={{ justifyContent: 'space-around' }}>
                        <Typography variant="h4" style={{ fontfamily: 'Montserrat', fontSize: '22px', lineHeight: '25.2px' }} mt={2} mb={2}>
                            Comprometidos con el medio ambiente.
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '25.2px' }}>
                            El
                            {' '}
                            <strong>cambio climático</strong>
                            {' '}
                            es una de las problemáticas ambientales con incidencia global más importante de las últimas décadas. Es por eso que Nubi2Go asumió el compromiso de
                            {' '}
                            <strong>medir la huella de carbono</strong>
                            , trabajamos por reducirla y asumimos un compromiso de compensarla mediante la plantación de árboles nativos en la
                            {' '}
                            <strong>Yunga Tucumana</strong>
                            , contribuyendo a la
                            {' '}
                            <strong>restauración de bosques nativos.</strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Layout>
);

export default AboutUs;
